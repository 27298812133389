import Vue from 'vue'
import Router from 'vue-router'
import project from './../project/index'

const Home = () => import('./../views/home.vue')
const HomeEng = () => import('./../views/home_eng.vue')
const Exhibition = () => import('../views/exhibition')
const ExhibitionDetail = () => import('../views/exhibition/detail.vue')
const ExhibitionBackups = () => import('../views/exhibition/backups.vue')

const ExhibitionEng = () => import('../views/exhibition_eng')
const ExhibitionEngDetail = () => import('../views/exhibition_eng/detail.vue')
const ExhibitionEngBackups = () => import('../views/exhibition_eng/backups.vue')

const News = () => import('../views/news')
const NewsDetail = () => import('../views/news/detail.vue')

const NewsEng = () => import('../views/news_eng')
const NewsEngDetail = () => import('../views/news_eng/detail.vue')

const Press = () => import('../views/press')
const PressDetail = () => import('../views/press/detail.vue')

const PressEng = () => import('../views/press_eng')
const PressEngDetail = () => import('../views/press_eng/detail.vue')

const Aboutus = () => import('../views/aboutus')
const AboutusEng = () => import('../views/aboutus_eng')
const Contactus = () => import('../views/contactus')
const ContactusEng = () => import('../views/contactus_eng')
const Collection = () => import('../views/collection')
const CollectionDetail = () => import('../views/collection/detail.vue')
const CollectionEng = () => import('../views/collection_eng')
const CollectionEngDetail = () => import('../views/collection_eng/detail.vue')
const Activity = () => import('../views/activity')
const ActivityDetail = () => import('../views/activity/detail.vue')
const ActivityEng = () => import('../views/activity_eng')
const ActivityEngDetail = () => import('../views/activity_eng/detail.vue')
const Login = () => import('./../views/login.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: `/${project.mid}/`,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/home_eng',
      name: 'home_eng',
      component: HomeEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition',
      name: 'exhibition',
      component: Exhibition,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_detail',
      name: 'exhibition_detail',
      component: ExhibitionDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_backups',
      name: 'exhibition_backups',
      component: ExhibitionBackups,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_eng',
      name: 'exhibition_eng',
      component: ExhibitionEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_eng_detail',
      name: 'exhibition_eng_detail',
      component: ExhibitionEngDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_eng_backups',
      name: 'exhibition_eng_backups',
      component: ExhibitionEngBackups,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/news',
      name: 'news',
      component: News,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/news_detail',
      name: 'news_detail',
      component: NewsDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/news_eng',
      name: 'news_eng',
      component: NewsEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/news_eng_detail',
      name: 'news_eng_detail',
      component: NewsEngDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/press',
      name: 'press',
      component: Press,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/press_detail',
      name: 'press_detail',
      component: PressDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/press_eng',
      name: 'press_eng',
      component: PressEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/press_eng_detail',
      name: 'press_eng_detail',
      component: PressEngDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: Aboutus,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/aboutus_eng',
      name: 'aboutus_eng',
      component: AboutusEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: Contactus,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/contactus_eng',
      name: 'contactus_eng',
      component: ContactusEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/collection',
      name: 'collection',
      component: Collection,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/collection_detail',
      name: 'collection_detail',
      component: CollectionDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/collection_eng',
      name: 'collection_eng',
      component: CollectionEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/collection_eng_detail',
      name: 'collection_eng_detail',
      component: CollectionEngDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/activity',
      name: 'activity',
      component: Activity,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/activity_detail',
      name: 'activity_detail',
      component: ActivityDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/activity_eng',
      name: 'activity_eng',
      component: ActivityEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/activity_eng_detail',
      name: 'activity_eng_detail',
      component: ActivityEngDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '*',
      redirect: '/home'
    },
  ]
})
