<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style>
#app {
  font-family: 'PingFang SC', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.line_s {
  margin: 40px 10vw;
  height: 1px;
  background-color: #999999a1;
  position: relative;
  animation: moveRight 2s 1 linear;
}

@keyframes moveRight {
  0% {
    width: 0px;
  }

  100% {
    width: 80%;
  }
}
</style>